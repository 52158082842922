.products-hero-banner img {
    width: 100%;
}
.products-hero-banner .left .imgbox {
    position: relative;
}
.products-hero-banner .left .imgbox::after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(9, 9, 121, 0) 60%, #072754 100%);
    top: 0;
    left: 0;
}

.products-hero-banner .imgbox img{
    width: 100%;
}

.products-hero-banner .container {
    display: flex;
    position: relative;
}
.products-hero-banner .left {
    flex: 1.5;
}

.products-hero-banner .right {
    padding: 0rem 3rem;
    z-index: 1;
    flex: 2;
}
.products-hero-banner .right .contentbox {
    width: 89%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    position: relative;
    z-index: 2;
}
.products-hero-banner .right .contentbox h2, .boxes-container .box .right .contentbox h2 {
    color: #fff;
    padding-bottom: 10px;
    margin: 0;
    font-size: 26px;
}
.products-hero-banner .right .contentbox p, .boxes-container .box .right .contentbox p {
    margin: 0;
    line-height: 1.7em;
    color: #fff;
    padding-bottom: 0;
    font-size: 14px;
}
.boxes-container {
    padding: 27px 0;
}
.boxes-container .box {
    display: flex;
    /* background: #072754; */
    padding: 0;
    width: 100%;
    max-width: 100%;
    position: relative;
}
.boxes-container .box .left {
    flex: 1.5;
}
.boxes-container .box  .right {
    padding: 0rem 3rem;
    z-index: 1;
    flex: 2;
    position: absolute;
    top: 33%;
    width: 75%;
}
.boxes-container .box .right .contentbox {
    width: 89%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    color: #fff;
}
.both-wrap {
    width: 80%;
    max-width: 1080px;
    margin: auto;
}
.both-wrap .boxes-container .box {
    margin: 4rem 0;
    border-radius: 6px;
}
.both-wrap .boxes-container img{
    width: 100%;
}
.both-wrap .boxes-container .box::after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: linear-gradient(86deg, #072754 0%, rgb(9 9 121 / 26%) 100%, rgba(2, 0, 36, 0) 0%);
    top: 0;
    border-radius: 6px;
}
.products-hero-banner .right::after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: linear-gradient(175deg, rgb(2 0 36 / 0) 0%, rgb(1 1 40 / 21%) 0%, #082c5d 100%);
    top: 0;
    left: 0;
}
.boxes-container .box .right .contentbox p {
    width: 89%;
    font-size: 14px;
}

@media(max-width:1024px){
    .products-hero-banner .right .contentbox{
        width: 100%;
        flex-wrap: wrap;
    }
    .products-hero-banner .container {
        margin-top: 0;
        flex-wrap: wrap;
    }
    .products-hero-banner .left {
        flex: unset;
    }
    .products-hero-banner .right, .products-hero-banner .left .imgbox {
        padding: 10px;
    }
    .both-wrap {
        width: 100%;
    }
    .boxes-container .box .right .contentbox {
        flex-wrap: wrap;
        width: 100%;
    }
    .boxes-container .box .right {
        width: 100%;
        position: unset;
        padding: 10px;
    }
    .boxes-container .box .left {
        flex: unset;
    }
    .both-wrap .boxes-container .box {
        margin: 10px 0;
        flex-wrap: wrap;
    }
    .both-wrap .boxes-container img {
        width: 100%;
    }
    .products-hero-banner .imgbox img{
        width: 100%;
    }
    .boxes-container {
        padding: 0;
    }
}

@media(max-width:768px){
    .boxes-container .box .right .contentbox p {
        width: 100%;
    }
    .products-hero-banner .right .contentbox p, .boxes-container .box .right .contentbox p {
        font-size: 16px;
    }
}