.about-accordion h5 {
    margin: 0;
    font-size: 26px;
    color: #072254;
}
.about-accordion .toggle-content p {
    margin: 0;
    padding-top: 20px;
    color: #fff;
}
.about-accordion .toggle-content p {
    margin: 0;
    padding-top: 20px;
    color: #fff;
    font-size: 14px;
    line-height: 1.7em;
}
.about-accordion {
    background-color: #00a2e9;
    padding: 40px;
    text-align: center;
    margin: 54px auto;
}
.about-accordion p.center-text {
    padding-top: 0;
}
.about-page .slider-content .slide-description h3 {
    margin: 0;
    color: #072254;
    font-size: 22px;
    padding-bottom: 10px;
    line-height: 1em;
}
.about-page .about-text.section-header h3 {
    font-weight: 600;
    font-size: 26px;
    color: #072254;
    text-align: center;
}
.about-page img {
    width: 100%;
}
.about-page .about-column.full-column .about-text.main-blurb p {
    text-align: center;
    font-size: 18px;
    color: #072254;
}
.about-column.main-column .about-text.main-text h2 {
    margin: 0;
    font-weight: 700;
    font-size: 35px;
    color: #ffffff;
}
.about-page .about-column.full-column .about-text.main-blurb {
    max-width: 900px;
    margin: auto;
    padding: 27px 0;
}
.about-page section.about-section .about-column.half-column .about-description h4 {
    margin: 0;
    padding-bottom: 10px;
    line-height: 1em;
    color: #072254;
    font-size: 18px;
}
.about-page section.about-section .about-column.half-column .about-image {
    margin-bottom: 30px;
}
.about-page section.about-section .about-column img {
    border-radius: 5px;
}
.about-page section.about-section div#collab-section {
    display: flex;
}
.about-page section.about-section div#collab-section {
    display: flex;
    margin: auto;
    padding: 27px 0;
}
.about-page section.about-section div#collab-section .about-column.half-column {
    width: 47.25%;
    margin-right: 5.5%;
}
.about-page section.about-section .about-column.half-column .about-description p {
    margin: 0;
    font-size: 14px;
    color: #072254;
    line-height: 1.7em;
}
.about-row {
    max-width: 1080px;
    margin: auto;
}
.about-page section.about-section .about-column.full-column .slide-description p {
    padding-bottom: 1em;
    margin: 0;
    font-size: 14px;
    color: #072254;
    line-height: 1.7em;
}

.about-page section.about-section .about-column.full-column .slide-image img {
    border-radius: 5px;
    box-shadow: 0px 0px 8px 0px #c2cfc6;
}
.about-page section.about-section .about-column.full-column .slider-content {
    display: flex;
    padding: 27px 0;
    flex-wrap: wrap;
}
.about-page section.about-section .about-column.full-column .slide-image {
    width: 47.25%;
    margin-right: 5.5%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.about-page section.about-section .about-column.full-column .slider-content .slide-description {
    width: 47.25%;
}
section.about-section.with-bg {
    padding-top: 10vw;
    padding-bottom: 8vw;
    position: relative;
    padding-right: 0;
    padding-left: 0;
}
.with-bg {
    padding: 20px;
    background: url('../../images/backgroundfirst.jpg') no-repeat center center;
    background-size: cover;
  }
  .about-page .et_pb_bottom_inside_divider {
    background-image: var(--wave);
    background-position: bottom center;
    transform: rotateY(0deg);
  }
  .about-row .about-text.main-text {
    font-size: 16px;
    margin-bottom: 4rem;
}
section.about-section.with-bg .about-row:nth-child(1) {
    margin-left: 3rem;
    padding: 27px 0;
}

@media(max-width:1024px){
    .about-page section.about-section div#collab-section{
        flex-wrap: wrap;
    }
    .about-page section.about-section div#collab-section .about-column.half-column {
        width: 100%;
        margin: 10px;
    }
    .about-page section.about-section .about-column.full-column .slide-image {
        width: 100%;
        margin: 10px;
    }
    .about-page section.about-section .about-column.full-column .slider-content .slide-description {
        width: 100%;
        margin: 10px;
    }
    .about-page .about-column.full-column .about-text.main-blurb {
        max-width: 100%;
        margin: 10px;
    }
    .about-accordion {
        margin: 54px auto 0 auto;
        padding: 20px;
    }
    
}

@media(max-width:768px){
    section.about-section.with-bg .about-row:nth-child(1) {
        padding: 27px;
        margin-left: 0;
    }
    .about-page section.about-section .about-column.half-column .about-description p, .about-page section.about-section .about-column.full-column .slide-description p {
        font-size: 16px;
    }
    .about-page section.about-section .about-column.half-column .about-description h4, .about-page .slider-content .slide-description h3 {
        font-size: 24px;
    }
}