.card {
  padding: 16px 12px 12px 16px;
  margin: 1px;
  text-align: left;
  background-color: #072254;
  height: 160px;
}

.card-content {
  display: flex;
  align-items: center;
}

.card-image .image-wrap img {
  width: 100%;
}

.card-header {
  font-size: 1.5em;
  margin: 0;
}

.card-description {
  margin-top: 10px;
}
.card-container h3 span {
  color: #00a2e9;
  padding-bottom: 10px;
  line-height: 1em;
  font-weight: 500;
  font-size: 20px;
}
.card-description p {
  color: #fff;
  margin: 0;
  font-size: 14px;
  line-height: 1.7em;
}
.card-container {
  display: flex;
  width: 80%;
  max-width: 1080px;
  margin: 1rem auto 0 auto;
  padding: 54px 0;
}
.card-image {
  display: flex;
}
.card-container span.image-wrap {
  width: 75px;
  padding-right: 15px;
}

@media (max-width: 1024px) {
  .card-container {
    flex-wrap: wrap;
    width: 100%;
  }
}
